import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
export const meta = {
  published: false,
  publishedAt: "2019-01-17",
  title: "How I dream of styling my React apps",
  summary: ""
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`As the co-creator of styled-components I have spent the past four years thinking about styling React components. Prompted by a lot of conversations and inspirations I have a new dream.`}</p>
    <p>{`It looks like this:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{`div
  styles`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    flexDirection`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"column"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"row"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    margin`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    padding`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
    color`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"primary"`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{`
`}</code></pre>
    <p>{`Look closely, there are a lot of interesting ideas in here:`}</p>
    <ul>
      <li parentName="ul">{`No unnecessary naming`}</li>
      <li parentName="ul">{`Enforced consistency via a built-in scale`}</li>
      <li parentName="ul">{`Per-value responsiveness`}</li>
      <li parentName="ul">{`Simple composition`}</li>
      <li parentName="ul">{`Simple refactoring`}</li>
      <li parentName="ul">{`Static typing for styles`}</li>
    </ul>
    <p>{`Let's examine the benefits and tradeoffs.`}</p>
    <h3>{`Style Objects`}</h3>
    <p>{`Compared to writing CSS in JS as strings (like styled-components uses), objects are are less familiar to developers with no JavaScript experience and are not as easily copy-and-pasted.`}</p>
    <p>{`On the other hand, the visual noise caused by dynamic interpolations is reduced and composing objects is much simpler.`}</p>
    <h3>{`Prop Based`}</h3>
    <p>{`Second, you'll notice that I'm passing the style object to a prop of the element. Why a prop and not the familiar `}<inlineCode parentName="p">{`styled.x`}</inlineCode>{` API or a `}<inlineCode parentName="p">{`withStyles`}</inlineCode>{` HOC?`}</p>
    <p>{`Naming is one of the hardest things about coding, and with the prop there is no need to create either class names (`}<inlineCode parentName="p">{`.name`}</inlineCode>{`) or intermediate components (`}<inlineCode parentName="p">{`Name`}</inlineCode>{`). All you have to name is the component you are creating, nothing more.`}</p>
    <p>{`It also makes refactoring simple, you can copy entire blocks of JSX around without worrying about other parts of your code like HOCs or styled components.`}</p>
    <p>{`The prop is a small abstraction on top of standard React APIs, making it easy to learn. Further, it can support everything other CSS-in-JS libraries can, including adapting based on props and state, extending and theming.`}</p>
    <h4><inlineCode parentName="h4">{`styles`}</inlineCode>{` vs. `}<inlineCode parentName="h4">{`css`}</inlineCode></h4>
    <p>{`I call it `}<inlineCode parentName="p">{`styles`}</inlineCode>{` vs. the (currently) more common `}<inlineCode parentName="p">{`css`}</inlineCode>{` because that makes it learn once, write anywhere (like React itself). Styling your React Native or ReactVR apps with a `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop doesn't make sense, they don't support CSS.`}</p>
    <h4>{`Are these inline styles?`}</h4>
    <p>{`They could be, but are not. Inline styles are less performant than compiling to CSS and injecting it into the CSSOM, and they also do not support pseudo-selectors and other nice parts of CSS.`}</p>
    <h3>{`Built-in Scale`}</h3>
    <p>{`Rather than specifying arbitrary values (`}<inlineCode parentName="p">{`margin: '16px'`}</inlineCode>{` or `}<inlineCode parentName="p">{`margin: 16`}</inlineCode>{`), only values from 1 to 10 can be specified. There is a global scale, e.g. 4-based: `}<inlineCode parentName="p">{`4px 8px 16px 32px 64px...`}</inlineCode>{` (`}<inlineCode parentName="p">{`4 * 2 ^ n`}</inlineCode>{`), and the value defines which step on the scale is used. For example, `}<inlineCode parentName="p">{`margin: 2`}</inlineCode>{` would result in a `}<inlineCode parentName="p">{`8px`}</inlineCode>{` margin.`}</p>
    <p>{`The same thing is true for colors, rather than allowing any hex/rgb value there is a global set of colors with names, and you pass the color name rather than a raw value.`}</p>
    <p>{`This means your app is always consistently styled. All the spacing and colors are automatically uniform, leveling up the design. You also have less mental overhead, as you no longer have to decide between a `}<inlineCode parentName="p">{`4px`}</inlineCode>{` and `}<inlineCode parentName="p">{`5px`}</inlineCode>{` margin, but between a `}<inlineCode parentName="p">{`4px`}</inlineCode>{` and `}<inlineCode parentName="p">{`8px`}</inlineCode>{` margin.`}</p>
    <blockquote>
      <p parentName="blockquote">{`TODO: ugly escape hatch for edge cases? maybe `}<inlineCode parentName="p">{`import { __raw }; { margin: __raw('13px') };`}</inlineCode></p>
    </blockquote>
    <h3>{`Responsive Values`}</h3>
    <p>{`Rather than using media queries each property can be responsive (a la facepaint) with mobile-first values.`}</p>
    <p>{`Again there is a global scale for media queries (e.g. `}<inlineCode parentName="p">{`0px 768px 1200px 2400px`}</inlineCode>{`), and specifying `}<inlineCode parentName="p">{`margin: [1, 2]`}</inlineCode>{` would mean that anything above 768px has a margin of 8px and everything below a margin of 4px. Need to make a grid of things a column on mobile? `}<inlineCode parentName="p">{`flexDirection: ['column', 'row']`}</inlineCode>{`!`}</p>
    <p>{`This makes it convient and quick to add responsiveness to your app. Because the media queries are mobile-first, it also encourages starting from the mobile layout first, which is a good idea for most apps.`}</p>
    <blockquote>
      <p parentName="blockquote">{`TODO: Maybe the prop should accept an array to allow swapping out all values? `}<inlineCode parentName="p">{`styles={[mobile, desktop]}`}</inlineCode>{`, or maybe that should merge styles? not sure`}</p>
    </blockquote>
    <h3>{`Bonus: Static Typing`}</h3>
    <p>{`The style objects can be fully statically typed with TypeScript or Flow!`}</p>
    <h3>{`Open Questions`}</h3>
    <ul>
      <li parentName="ul">{`Extending styles: what happens here `}<inlineCode parentName="li">{`<CustomComp styles={{}}>`}</inlineCode>{`? Does it pass through `}<inlineCode parentName="li">{`className/style`}</inlineCode>{`, `}<inlineCode parentName="li">{`styles`}</inlineCode>{` or both?`}</li>
    </ul>
    <h3>{`Implementation`}</h3>
    <p>{`Identical to emotions `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop, this should be implemented as a custom `}<inlineCode parentName="p">{`React.createElement`}</inlineCode>{` replacement and handle server-side rendering out of the box.`}</p>
    <p>{`All the validation should only happen in development. Since there is only simple properties, there is no need for a CSS parser either. This should have a tiny runtime bundle size (< 2kB?).`}</p>
    <h3>{`Inspiration / Further Reading`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mrmrs.cc/writing/2018/06/18/component-styling-api"
        }}>{`https://mrmrs.cc/writing/2018/06/18/component-styling-api`}</a>{` by @mrmrs`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jxnblk.com/writing/posts/patterns-for-style-composition-in-react"
        }}>{`https://jxnblk.com/writing/posts/patterns-for-style-composition-in-react`}</a>{` by @jxnblk`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://style-components.com"
        }}>{`https://style-components.com`}</a>{` by @chantastic`}
        <ul parentName="li">
          <li parentName="ul">{`especially `}<a parentName="li" {...{
              "href": "https://medium.learnreact.com/scale-fud-and-style-components-c0ce87ec9772"
            }}>{`https://medium.learnreact.com/scale-fud-and-style-components-c0ce87ec9772`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/threepointone/glam"
        }}>{`https://github.com/threepointone/glam`}</a>{` by @threepointone`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/jxnblk/rebass"
        }}>{`https://github.com/jxnblk/rebass`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/emotion-js/facepaint"
        }}>{`https://github.com/emotion-js/facepaint`}</a></li>
    </ul>
    <h3>{`Primitive properties only`}</h3>
    <p>{`No media queries, pseudo selectors, pseudo elements, shorthands, or anything else.`}</p>
    <p>{`Clarity over brevity (`}<inlineCode parentName="p">{`margin: 1 1 2 2`}</inlineCode>{` vs. `}<inlineCode parentName="p">{`{ marginTop: 1, marginBottom: 2, marginRight: 1, marginLeft: 2 }`}</inlineCode>{`).`}</p>
    <p>{`Need styles on interaction? Do it in JS. CSS pseudo selectors usually do not work well on mobile, and you resolve to JS.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      